import localforage from "localforage";
import { apiUrl, apiVersion } from "@/config";
export async function getTicketsByEventId (eventId) {
    const token = await localforage.getItem("nakamian.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/event/${ eventId }/ticket`,
        {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
        }
    );
    if (response.ok) {
        const tickets = await response.json();
        if (tickets) {
            return tickets;
        }
        throw "EVENT TICKET GET error";
    }
    if (response.status >= 400) {
        throw await response.text();
    }
    throw "Internal server error";
}
export default getTicketsByEventId;
