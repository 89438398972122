<template>
    <side-panel class="activity-details d-flex">
        <div class="position-sticky title d-flex justify-content-between px-3 pt-3 pb-2">
            <div class="mr-2">
                <router-link class="btn btn-link border-0 rounded-0"
                             to="/activities">
                    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                </router-link>
            </div>
            <div class="flex-grow-1">
                <h3 class="font-weight-bolder mb-0">
                    {{ title }}
                </h3>
            </div>
        </div>
        <div class="flex-grow-1 overflow-auto p-3">
            <div class="date row no-gutters">
                <div class="col-5">
                    <dt class="">
                        Start
                    </dt>
                    <dd>
                        {{ activity ? activity.startTime : "" | dateFnsFormatter("p") }}<br>
                        {{ activity ? activity.startTime : "" | dateFnsFormatter("d MMMM yyyy") }}
                    </dd>
                </div>
                <div class="col-2 h1 m-0 text-secondary d-flex justify-content-center align-items-center">
                    <font-awesome-icon v-bind:icon="['far', 'long-arrow-right']"></font-awesome-icon>
                </div>
                <div class="col-5 text-right">
                    <dt class="">
                        End
                    </dt>
                    <template v-if="activity && activity.endDate">
                        <dd v-if="activity.endTime">
                            {{ activity ? activity.endTime : "" | dateFnsFormatter("p") }}<br>
                            {{ activity ? activity.endDate : "" | dateFnsFormatter("d MMMM yyyy") }}

                        </dd>
                        <dd v-else>
                            11:59 PM<br>
                            {{ activity ? activity.endDate : "" | dateFnsFormatter("d MMMM yyyy") }}
                        </dd>
                    </template>
                    <template v-else>
                        <dd v-if="activity && activity.endTime">
                            {{ activity ? activity.endTime : "" | dateFnsFormatter("p") }}<br>
                            {{ activity ? activity.startTime : "" | dateFnsFormatter("d MMMM yyyy") }}
                        </dd>
                        <dd v-else>
                            11:59 PM<br>
                            {{ activity ? activity.startTime : "" | dateFnsFormatter("d MMMM yyyy") }}
                        </dd>
                    </template>
                </div>
            </div>
            <div class="location mb-2">
                <label class="font-weight-bolder mb-1">
                    🌏 Region
                </label>
                <p class="mb-0 text-dark">
                    {{ regionName }}
                </p>
            </div>
            <div class="location mb-2">
                <label class="font-weight-bolder mb-1">
                    🏢 Location
                </label>
                <p class="mb-0 text-dark">
                    {{ location }}
                </p>
            </div>
            <div class="price mb-2">
                <label class="font-weight-bolder mb-1">
                    💲 Price
                </label>
                <p class="mb-0 text-dark">
                    {{ price }}
                </p>
            </div>
            <div class="cover mb-3"
                 v-if="activity && activity.images && activity.images.length > 0"
                 v-on:click="isLightBoxOpened = true">
                <img class="img-thumbnail rounded-xl shadow-sm"
                     v-bind:alt="activity ? activity.title : ''"
                     v-bind:src="`${ apiUrl }/${ apiVersion }/event/image/${ activity.images[0] }`">
            </div>
            <div class="description mb-3">
                <label class="font-weight-bolder mb-1">
                    📃 Description
                </label>
                <p class="mb-0 text-dark">
                    {{ description }}
                </p>
            </div>
            <!-- <div class="participants mb-2">
                <label class="font-weight-bolder mb-1">
                    <span class="mr-1">
                        Participants
                    </span>
                    <span class="badge badge-primary">
                        {{ ticketCount }} / {{ activity ? activity.available : "" }}
                    </span>
                </label>
                <div class="d-flex flex-wrap">
                    <template v-if="tickets.length > 0">
                        <participant-icon class="mr-2 mb-1"
                                          v-for="ticket in tickets.slice(0, 10)"
                                          v-bind:key="ticket._id"
                                          v-bind:ticket="ticket">
                        </participant-icon>
                        <div class="text-right"
                             v-if="tickets.length > 10">
                            and {{ tickets.length - 10 }} more
                        </div>
                        <div class="d-flex justify-content-end w-100">
                            <router-link class="btn btn-sm btn-link"
                                         v-bind:to="`/activities/${ activityId }/participants`">
                                More...
                            </router-link>
                        </div>
                    </template>
                    <ul class="list-group w-100"
                        v-else>
                        <li class="list-group-item text-center">
                            No participant yet
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
        <div class="p-3">
            <div class="d-flex mb-2">
                <button class="btn btn-block btn-warning rounded-pill mb-0 mr-1"
                        v-on:click="isModalEditEventOpen = true">
                    ✏️ Update
                </button>
                <button class="btn btn-block btn-danger rounded-pill mt-0 ml-1"
                        v-on:click="isModalEditEventOpen = true">
                    🗑️ Cancel
                </button>
            </div>
            <button class="btn btn-block btn-info rounded-pill shadow-sm"
                    v-bind:class="{ 'btn-loading': isLoading, }"
                    v-on:click="isModalChatsOpen = true">
                📨 Send to Chat
            </button>
        </div>

        <modal-chats is-multiple
                     v-bind:selected.sync="chatsSelected"
                     v-model="isModalChatsOpen"
                     v-on:selected="sendActivity">
        </modal-chats>
        <modal-activity is-editing
                        v-bind:event="activity"
                        v-model="isModalEditEventOpen">
        </modal-activity>
        <modal-light-box v-bind:src="`${ apiUrl }/${ apiVersion }/event/image/${ activity && activity.images ? activity.images[0] : '' }`"
                         v-model="isLightBoxOpened">
        </modal-light-box>
    </side-panel>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config.js";
import { sendMessage, } from "@/workers/whatsapp.worker.js";
import getTicketsByEventId from "../../api/activity/getTicketsByEventId.js";
export default {
    name: "EventDetailsPanel",
    components: {
        SidePanel: () => import("../SidePanel.vue"),
        // ParticipantIcon: () => import("./ActivityDetails/ParticipantIcon.vue"),
        ModalLightBox: () => import("../modals/ModalLightBox.vue"),
        ModalActivity: () => import("./ModalActivity.vue"),
        ModalChats: () => import("@/components/ModalChats.vue"),
    },
    props: {
        "activityId": {
            "type": String,
        },
    },
    data () {
        return {
            apiUrl, apiVersion,

            isLoading: false,
            isModalEditEventOpen: false,
            isModalChatsOpen: false,
            isLightBoxOpened: false,

            tickets: [],
            chatsSelected: [],
        };
    },
    computed: {
        activity () {
            return this.$store.getters["event/itemById"](this.activityId);
        },
        title () {
            return this.activity?.title ?? "";
        },
        region () {
            return this.$store.getters["region/itemByCode"](this.activity?.region);
        },
        regionName () {
            return this.region?.name ?? "";
        },
        location () {
            return this.activity?.location ?? "";
        },
        price () {
            return this.activity?.price ?? "";
        },
        description () {
            return this.activity?.description ?? "";
        },
        ticketCount () {
            return this.tickets.length;
        },
    },
    methods: {
        async sendActivity () {
            const attributes = `*地區*：${ this.regionName }\n*地點*：${ this.location }\n*價錢*：${ this.price }\n`;
            const message = `*${ this.title }*\n${ this.description }\n${ attributes }`;
            try {
                this.isLoading = true;
                await Promise.all(this.chatsSelected.map(({ vendorId }) => sendMessage(vendorId, message)));
                this.chatsSelected = [];
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
    async mounted () {
        if (this.activityId) {
            try {
                this.tickets = await getTicketsByEventId(this.activityId);
            } catch (e) {
                console.error(e);
            }
            console.log(this.tickets);
        }
    },
    watch: {
        async activityId (newVal) {
            if (newVal) {
                try {
                    this.tickets = await getTicketsByEventId(newVal);
                } catch (e) {
                    console.error(e);
                }
                console.log(this.tickets);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.title,
.actions {
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;
}

.title {
    top: 0;
}

.cover {
    cursor: pointer;
}

.description {
    & > p {
        white-space: pre-line;
    }
}
</style>
